<template>
  <div class="phone" @touchmove.prevent >
    <div class="nav" >
      <div class="logo">
        <img src="@/assets/phone/phone-logo.png">
      </div>
      <div @click="onHide" class="fork" >
        <span class="iconfont icon-chahao"></span>
      </div>
    </div>
    <div class="list" >
      <ul>
        <li @click="onskip(item.url)" v-for="(item, index) in list" :key="index" >{{item.name}}</li>
        <li @click="onEnglish" >EN</li>
      </ul>
    </div>
  </div>


</template>

<script>

export default {
  name: "phoneNav.vue",

  data() {
    return {
      list: [{
        url: '/',
        name: '首页'
      },{
        url: '/apparel',
        name: '穿戴设备'
      },{
        url: '/interaction',
        name: '交互控制'
      },{
        url: '/robot',
        name: '俱身智能'
      },{
        url: '/relation',
        name: '联系我们'
      },]
    }
  },

  methods: {
    onHide() {
      this.$emit("onHide")
    },

    onskip(url) {
      this.$router.push(url)
      this.$emit("onHide")

    },

    onEnglish() {
      window.location.href="/en"
    }
  },
}
</script>

<style scoped>

.phone {

  background-image: url("../../assets/phone/Group770.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 1.14rem 0.6rem 0;
}

.nav .logo {
  display: flex;
  align-items: center;
  height: 0.43rem;
}

.nav .logo img {
  height: 0.35rem;
}

.nav .fork {
  height: 0.43rem;
  line-height: 0.43rem;
  color: #FFFFFF;
}

.nav .fork .iconfont {
  font-size: 0.36rem;
  cursor: pointer;
}


 .list {
   padding-left: 0.9rem;
  padding-top: 0.7rem;
   padding-bottom: 4.7rem;
}

 .list ul li {
   margin-top: 1rem;
  list-style: none;
   text-decoration: none;
   font-family: 'PingFang SC';
   font-style: normal;
   font-weight: 600;
   width: 2rem;
   font-size: 0.32rem;
   line-height: 1;
   color: #FFFFFF;
   cursor: pointer;
   letter-spacing: 0.03rem;
}




</style>
