<template>
<div class="footer" :class="isHome ? '' : 'is-normal-footer'">
  <div class="centre">
    <div class="picture">
      <img class="logo" src="@/assets/logo_1.png">
      <div class="line"></div>
    </div>
    <div class="link">
    <div class="relation">
      <p>联系我们</p>
      <p>bp@maschinerobot.com</p>
    </div>
      <div class="message">
          <p><a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none; color: inherit; ">备案号：京ICP备 2022008549号</a></p>
        <p>© Copyright - MaschineRobot. All RightReserved</p>
      </div>
      <div class="page">
        <ul>
          <li><a @click="onSkip('/')" >首页</a></li>
          <li><a @click="onSkip('/apparel')" >穿戴设备</a></li>
          <li><a @click="onSkip('/interaction')" >交互控制</a></li>
          <li><a @click="onSkip('/robot')" >俱身智能</a></li>
          <li><a @click="onSkip('/relation')" >联系我们</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "index.vue",
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onIndex(site) {
      this.$router.push({
        path: site
      })
    },
    onTop() {
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 500
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    },

    onSkip(site) {
      if(this.$route.path === site) {
        this.onTop()
      } else {
        this.onIndex(site)
      }
    }
  },




}
</script>

<style scoped>
 .footer {
   padding-top: 94px;
   padding-bottom: 87px;
 }
 .is-normal-footer {
   background-image: linear-gradient(to top left,#814e54,#545f65,#8cafb8);
 }
.centre {
  width: 1200px;
  margin: 0 auto;
}

.centre .picture {
  position: relative;
}

.picture .logo {
  margin-right: 18px;
}
 .picture .logo img{
   width: 340px;
   height: 40px;
 }
.picture .line {
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 768px;
  height: 4px;
  background-color: #FFFFFF;
}

.centre .link {
  font-family: 'PingFang SC';
  padding-top: 95px;
  display: flex;
}

.centre .link .relation {
  font-family: 'PingFang SC';
  margin-top: 120px;
  margin-right: 150px;
  width: 280px;
  height: 80px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #FFFFFF;
}

.centre .link .relation p:nth-child(2){
  margin-top:5px
}

.centre .link .message {
  margin-top: 128px;
  width: 421px;
  font-size: 16px;
  line-height: 40px;
  opacity: 0.5;
  color: #FFFFFF;
}

.centre .link .page {
  margin-left: 202px;
  width: 80px;
}

.centre .link .page li {
  list-style: none;
  height: 40px;
}

.centre .link .page li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}


</style>
