<template>
  <div class="navigation-mini">
    <div class="nav">
        <div class="centre">
          <router-link to="/" class="logo">
            <img src="../../assets/logo_1.png">
          </router-link>
          <div class="link">
            <ul>
              <li v-for="(nav,index) in navs" :key="index" :class="index === navIndex ? 'active' : ''">
                <router-link :to="nav.url">{{nav.name}}</router-link>
              </li>
              <li class="lang" >
                <a href="javascript:;" ref="white" class="white">中文</a> <div class="vertical" ></div>
                <a href="/en" >EN</a>
              </li>
            </ul>
          </div>
        </div>
    </div>

  </div>
</template>

<script>

const navs = [
  {
    url: '/',
    name: '首页'
  },{
    url: '/apparel',
    name: '穿戴设备'
  },{
    url: '/interaction',
    name: '交互控制'
  },{
    url: '/robot',
    name: '俱身智能'
  },{
    url: '/relation',
    name: '联系我们'
  },
]


export default {
  data() {
    return {
      navs,
      isShow: false,
      lang: 0,
    }
  },
  props: {
    navIndex: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (this.$route.path === '/') {
      this.isShow = true
    } else if (this.$route.path === '/apparel') {
      this.isShow = true
    }
  }
}
</script>

<style scoped>

.nav {
  height: 136px;
}

.nav .centre {
  display: flex;
  justify-content: space-between;
  /*max-width: 1280px;*/
  /*min-height: 1200px;*/
  width: 1200px;
  margin: 0 auto;
}
.nav .centre .logo {
  padding-top: 50px;
}
.nav .centre .logo img{
  width: 340px;
  height: 40px;
}
.nav .centre .link {
  padding-top: 62px;
}

.nav .centre .link ul {
  display: flex;
}

.nav .centre .link ul li {
  margin: 0 23px;
  list-style: none;
  padding: 7px 0;
  font-size: 18px;
}

.nav .centre .link ul .lang {
  display: flex;
  margin-right: 0;
}

.nav .centre .link ul .lang .vertical {
  margin: 6px 12px 0;
  width: 1px;
  height: 12px;
  background-color: #D3D0D2;
}

.nav .centre .link ul .lang .white {
  opacity: 1;
}

.nav .centre .link ul li.active::after {
  content: '';
  display: block;
  margin: 8px auto 0;
  width: 24px;
  height: 3px;
  background-color: #FFFFFF;
}

.nav .centre .link ul li a {
  text-decoration: none;
  font-size: 18px;
  color: #FFFFFF;
  opacity: 0.6;
  font-family: 'PingFang SC';
  font-style: normal;
}

.nav .centre .link ul .active a {
  /*color: #FFFFFF;*/
  opacity: 1;
}


</style>
