<template>
  <div class="footer" >
  <div class="logo" >
    <img src="@/assets/phone/phone-logo.png">
  </div>
    <div class="line" ></div>
    <div class="text" >
      <p class="future" >
        Reshape the Future.
      </p>
      <p class="relation" >
        <span>联系我们</span><br />
        bp@maschinerobot.com
      </p>
      <p class="record" >
          <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none; color: inherit; ">备案号：京ICP备 2022008549号</a><br />
        © Copyright - MaschineRobot. All Right Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index.vue"
}
</script>

<style scoped>
a {
    text-decoration: none;
}

.footer {
  font-family: 'PingFang SC';
  background: linear-gradient(109.01deg, #90A8B0 11.08%, #7B5C60 102.12%);
  padding: 0.55rem 0.6rem 0.99rem;
}

.footer .logo img {
  height: 0.41rem;
}

.footer .line {
  margin-top: 0.34rem;
  height: 0.02rem;
  background-color: #FFFFFF;
  opacity: 0.2;
}

.footer .text {
  padding-top: 0.3rem;
}

.footer .text .future {
  font-style: normal;
  font-weight: 400;
  font-size: 0.28rem;
  /*line-height: 0.9rem;*/
  color: #FFFFFF;
}

.footer .text .relation {
  font-family: 'PingFang SC';
  margin-top: 0.79rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.26rem;
  line-height: 0.42rem;
  color: #FFFFFF;
}

.footer .text .relation span {
  letter-spacing: 0.02rem;
}

.footer .text .record {
  margin-top: 0.3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #FFFFFF;
  opacity: 0.5;
  letter-spacing: 0.01rem;
}

</style>
